import '../assets/css/nds.css';

import React, { Component } from "react"
import Formsy from 'formsy-react';
import { connect } from "react-redux"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import Steps from "../components/leaslystep"
import bottomLine from "../images/line.png"
import SEO from "../components/seo"
import * as actionCreators from './../store/actions';
import MyInput from "../components/MyInput"
import MyInputMask from "../components/MyInputMask";
import ButtonLoader from "../components/buttonLoader";
import MyAuto from "../components/MyAuto";

class nds2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      nds2Data:{sysAdminname:'',sysAdminPhone:'',sysAdminEmail:'',
      accName:'',accPhone:'',accEmail:'',
      sysUsrName1:'',sysUsrPhone1:'',sysUsrEmail1:'',access1:'',
      sysUsrName2:'',sysUsrPhone2:'',sysUsrEmail2:'',access2:'',
      sysUsrName3:'',sysUsrPhone3:'',sysUsrEmail3:'',access3:'',
      }
    };
  }

  componentDidMount = async () => {
    const { showLoader, hideLoader } = this.props;
    
    if(!sessionStorage.ndsver || sessionStorage.ndsver!==process.env.ndsver){
      sessionStorage.clear();
      sessionStorage.ndsver=process.env.ndsver;
    } 
    if(!sessionStorage.nds2Data){
        sessionStorage.nds2Data = window.btoa(JSON.stringify(this.state.nds2Data))
    } 
    this.state.nds2Data = JSON.parse(window.atob(sessionStorage.nds2Data))
    this.setState({nds2Data:JSON.parse(window.atob(sessionStorage.nds2Data))});
    
    //console.log(this.state.nds2Data)
    
     
  }

  sugChange = async(value,type) =>{
    let nds2Data = this.state.nds2Data;
    console.log(value,type)
    nds2Data[type] = value;
    this.setState({nds2Data:nds2Data});
   
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  enableButton = () => {
    this.setState({ canSubmit: true,infoError:"" });
  }
  submit = (model) => {
    const { showLoader, hideLoader} = this.props;
    const {nds2Data} = this.state;
    showLoader();
    model.access1 = nds2Data.access1;
    model.access2 = nds2Data.access2;
    model.access3 = nds2Data.access3;
    sessionStorage.nds2Data = window.btoa(JSON.stringify(model));
    
    hideLoader();
    navigate("/leasly3")
  }
  sugChange = async(value,type) =>{
    let nds2Data = this.state.nds2Data;
    nds2Data[type] = value;
    this.setState({nds2Data:nds2Data});
    
  }  
   
  render() {
    const { nds2Data, canSubmit } = this.state;
    const { buttonLoader} = this.props;
   
    return (
      <Layout layoutType="nds" >
        <section className="ndsec" ref={(input) => { this.nameInput = input; }}>
        <Steps></Steps>
          <div className="custom-container vehinfo">
            <div className="title-container">
              {/* <div className="icon-image">
                <img src={vehicalIcon} alt="icon" />
              </div> */}
              <div className="title-text">
                <h2>{'PRIMARY CONTACT'}</h2>
                <p></p>
              </div>
            </div>

            <div className="body-content">
              <Formsy ref="nds2Form" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>

              
              <div className="form-container-inline">
                    <div className="col3">
                        <div className="form-label-group error">
                            <MyInput name="sysAdminName" id="sysAdminName" placeholder="System Admin Name"  value={nds2Data.sysAdminName} validationError="Please enter a valid name."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:25}} required />
                        </div>

                    </div>

                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInput name="sysAdminEmail" id="sysAdminNameemail" placeholder="System Admin Email"  value={nds2Data.sysAdminEmail} validations="isEmail"
                                isLabelRequired={true} className="form-control" validationError="Please enter valid email" required />
                        </div>
                    </div>
                    
                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInputMask name="sysAdminPhone" id="sysAdminPhone" placeholder="System Admin Phone" mask="999-999-9999" value={nds2Data.sysAdminPhone} isLabelRequired={true}
                            className="form-control" validations={{minLength: 12,maxLength:15}} validationError="Please enter a valid Phone." required />
                        </div>
                    </div>
                </div>
              
              <div className="form-container-inline">
                    <div className="col3">
                        <div className="form-label-group error">
                            <MyInput name="accName" id="accName" placeholder="Accounting Name"  value={nds2Data.accName} validationError="Please enter a valid name."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:25}} required />
                        </div>

                    </div>

                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInput name="accEmail" id="accEmail" placeholder="Accounting Email"  value={nds2Data.accEmail} validations="isEmail"
                                isLabelRequired={true} className="form-control" validationError="Please enter valid email" required />
                        </div>
                    </div>
                    
                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInputMask name="accPhone" id="accPhone" placeholder="Accounting Phone" mask="999-999-9999" value={nds2Data.accPhone} isLabelRequired={true}
                            className="form-control" validations={{minLength: 12,maxLength:15}} validationError="Please enter a valid Phone." required />
                        </div>
                    </div>
                </div>

                <h3>ADDITIONAL SYSTEM USERS</h3>
                <div className="form-container-inline">
                    <div className="col3">
                        <div className="form-label-group error">
                            <MyInput name="sysUsrName1" id="name1" placeholder="Name"  value={nds2Data.sysUsrName1} validationError="Please enter a valid name."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:25}} />
                        </div>

                    </div>

                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInput name="sysUsrEmail1" id="email1" placeholder="Email"  value={nds2Data.sysUsrEmail1} validations="isEmail"
                                isLabelRequired={true} className="form-control" validationError="Please enter valid email" />
                        </div>
                    </div>
                    
                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInputMask name="sysUsrPhone1" value={nds2Data.sysUsrPhone1} id="phone1" placeholder="Phone" mask="999-999-9999"  isLabelRequired={true}
                            className="form-control" validations={{minLength: 12,maxLength:15}} validationError="Please enter a valid Phone." />
                        </div>
                    </div>
                    
                    <div className="col3">
                        <div className="form-label-group error">
                        <label>System Access</label>
                        <MyAuto value={nds2Data.access1} options={['Admin','Accounting','F&I','Service']}  sugChange={(e) => this.sugChange(e, 'access1')} name="access1" />
                        </div>
                    </div>

                </div>

                <div className="form-container-inline">
                    <div className="col3">
                        <div className="form-label-group error">
                            <MyInput name="sysUsrName2" id="name2" placeholder="Name"  value={nds2Data.sysUsrName2} validationError="Please enter a valid name."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:25}} />
                        </div>

                    </div>

                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInput name="sysUsrEmail2" id="email2" placeholder="Email"  value={nds2Data.sysUsrEmail2} validations="isEmail"
                                isLabelRequired={true} className="form-control" validationError="Please enter valid email" />
                        </div>
                    </div>
                    
                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInputMask name="sysUsrPhone2" value={nds2Data.sysUsrPhone2} id="phone2" placeholder="Phone" mask="999-999-9999"  isLabelRequired={true}
                            className="form-control" validations={{minLength: 12,maxLength:15}} validationError="Please enter a valid Phone." />
                        </div>
                    </div>
                    
                    <div className="col3">
                        <div className="form-label-group error">
                        <label>System Access</label>
                        <MyAuto value={nds2Data.access2} options={['Admin','Accounting','F&I','Service']}  sugChange={(e) => this.sugChange(e, 'access2')} name="access2" />
                        </div>
                    </div>

                </div>

                <div className="form-container-inline">
                    <div className="col3">
                        <div className="form-label-group error">
                            <MyInput name="sysUsrName3" id="name3" placeholder="Name"  value={nds2Data.sysUsrName3} validationError="Please enter a valid name."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:25}} />
                        </div>

                    </div>

                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInput name="sysUsrEmail3" id="email3" placeholder="Email"  value={nds2Data.sysUsrEmail3} validations="isEmail"
                                isLabelRequired={true} className="form-control" validationError="Please enter valid email" />
                        </div>
                    </div>
                    
                    <div className="col3">
                        <div className="form-label-group error">
                        <MyInputMask name="sysUsrPhone3" value={nds2Data.sysUsrPhone3} id="phone3" placeholder="Phone" mask="999-999-9999"  isLabelRequired={true}
                            className="form-control" validations={{minLength: 12,maxLength:15}} validationError="Please enter a valid Phone." />
                        </div>
                    </div>
                    
                    <div className="col3">
                        <div className="form-label-group error">
                        <label>System Access</label>
                        <MyAuto value={nds2Data.access3} options={['Admin','Accounting','F&I','Service']}  sugChange={(e) => this.sugChange(e, 'access3')} name="access3" />
                        </div>
                    </div>

                </div>



                <div className="btn-container">
                    <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'btn disabled wizard-btn creat-btn' : 'btn wizard-btn creat-btn'}>
                        <ButtonLoader isLoading={buttonLoader} label={'Continue'} />
                    </button>
                </div>

              </Formsy>
              <div className="txtright">* Field is required</div>
            </div>
          </div>
        </section>
      </Layout>

    );
  }
}

const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    buttonLoader: state.primary.buttonLoader
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(nds2);